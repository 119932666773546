import { ReactComponent as LogoDesk } from "../../assets/logo/logo.svg";
import { ReactComponent as Instagram } from "../../assets/icons/icon-insta.svg";
import { ReactComponent as Facebook } from "../../assets/icons/icon-face.svg";
import { ReactComponent as Linkedin } from "../../assets/icons/icon-linkedin.svg";
import { ReactComponent as Mail } from "../../assets/icons/icon-mail.svg";
export default function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__container">
          <div className="footer__logo">
            <LogoDesk />
            <p className="footer__logo-title">
              Aqui só ganhamos quando todos ganham.
            </p>
          </div>
          <div className="footer__midias">
            <ul className="footer__list">
              <li className="footer__list-item">
                <Facebook />
                /projectivadt
              </li>
              <li className="footer__list-item">
                <Linkedin />
                /projectiva-dt
              </li>
              <li className="footer__list-item">
                <Instagram />
                /projectivatecnologia
              </li>
              <li className="footer__list-item">
                <Mail />
                contato@projectivadt.com.br
              </li>
            </ul>
          </div>
        </div>
        <div className="footer__copyright">
          <div>
            <p className="footer__copyright-text">
              &copy; 2022 - Projectiva Tecnologia - Todos os direitos reservados.
            </p>
          </div>
          <div>
            <p className="footer__copyright-city">
              PORTO ALEGRE | CURITIBA | SÃO PAULO
            </p>
          </div>
        </div>
      </div>

    </footer>
  );
}
