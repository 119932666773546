import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/bundle";
import "swiper/css/free-mode";

import ClientAdvb from "../../assets/clients/client-advb.png";
import ClientCredoro from "../../assets/clients/client-credoro.png";
import ClientProcer from "../../assets/clients/client-procer.png";
import ClientRVM from "../../assets/clients/client-rvm.png";
import ClientSB from "../../assets/clients/client-sb.png";
import ClientSimon from "../../assets/clients/client-simon.png";
import ClientSolubio from "../../assets/clients/client-solubio.png";
import ClientUnesc from "../../assets/clients/client-unesc.png";
import ClientUsaflex from "../../assets/clients/client-usaflex.png";

export default function SectionClient() {
  return (
    <>
      <section className="client">
        <div className="container">
          <section className="client__header">Clientes e Parceiros</section>
          <div className="slider">
            <Swiper
              slidesPerView={1}
              navigation={true}
              modules={[Navigation]}
              loop
              centeredSlides={true}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
                992: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween:80,
                },
              }}
              className="mySwiper"
            >
              <div className="Swiper__container">
                <SwiperSlide>
                  <img src={ClientAdvb} alt="cliente" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={ClientCredoro} alt="cliente" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={ClientProcer} alt="cliente" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={ClientRVM} alt="cliente" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={ClientSB} alt="cliente" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={ClientSimon} alt="cliente" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={ClientSolubio} alt="cliente" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={ClientUnesc} alt="cliente" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={ClientUsaflex} alt="cliente" />
                </SwiperSlide>
              </div>
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
}
