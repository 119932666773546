import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

export default function SectionServices() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);

  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  return (
    <section className="services">
      <div className="container">
        <div className="services__header">
          <h1 className="services__header-title">Serviços</h1>
        </div>
        <div className="slider">
          <Swiper
            loop={true}
            navigation={true}
            modules={[Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="services__slider">
                <div className="services__image">
                </div>
                <div className="services__text">
                  <h2 className="services__text-title">MVP Solutions</h2>

                  <p className="services__text-subtitle">
                    Desenvolvimento sob medida de aplicações mobile, web,
                    backend, soluções cloud e integrações entre sistemas
                    distribuídos.
                  </p>

                  <h2 className="services__text-title">PROJECTS</h2>
                  <p className="services__text-subtitle">
                    Abrange todo o ciclo, do levantamento até implementação e
                    suporte de aplicação. Os projetos podem ser contratados na
                    íntegra, com todas as etapas, ou parcialmente levando em
                    conta as seguintes fases: levantamento de requisitos,
                    arquitetura da solução, desenho de solução, configuração,
                    especificação funcional, especificação técnica,
                    desenvolvimento, testes, análise de performance e
                    qualidade e go-live. Isso tudo no modelo turn-key, ou
                    seja, escopo, prazo e investimento sem surpresas.
                  </p>

                  <h2 className="services__text-title">SQUADS</h2>
                  <p className="services__text-subtitle">
                    Focam no desenvolvimento rápido e na entrega contínua de
                    produtos com times especializados e dedicados.
                  </p>

                  <Button onClick={handleShow} className="btn button-primary">
                    Fale com a gente.
                  </Button>

                  <Modal show={show} onHide={handleClose} size="lg">
                    <Modal.Header closeButton>
                      <Modal.Title>_Contato</Modal.Title>
                    </Modal.Header>
                    <p className="modal-text">
                      Obrigado pelo seu interesse na Projectiva. Seja qual for
                      sua questão, ajudaremos a direcioná-la para o local
                      correto.
                    </p>
                    <Modal.Body>
                      <div className="container">
                        <div className="row">
                          <Form.Group
                            className="col-lg-6 col-12"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Nome</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Leonardo Tavares"
                            />
                          </Form.Group>

                          <Form.Group
                            className="col-lg-6 col-12"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Empresa</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Projectiva Tecnologia Ltda"
                            />
                          </Form.Group>
                        </div>
                        <div className="row">
                          <Form.Group
                            className="col-lg-6 col-12"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>E-mail</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="leonardo.tavares@projectivadt.com.br"
                            />
                          </Form.Group>

                          <Form.Group
                            className="col-lg-6 col-12"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Telefone</Form.Label>
                            <Form.Control
                              type="phone"
                              placeholder="(11) 99999-9999"
                            />
                          </Form.Group>
                          <Form.Group
                            className=" col-12"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label>Como podemos ajudar?</Form.Label>
                            <Form.Control as="textarea" rows={3} />
                          </Form.Group>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        className="btn button-primary"
                        onClick={handleClose}
                      >
                        Enviar
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="services__slider">
                <div className="services__image business">
                </div>
                <div className="services__text">
                  <h2 className="services__text-title">
                    BUSINESS AGILE TRANSFORMATION
                  </h2>{" "}
                  <p className="services__text-subtitle">
                    Transformam as jornadas digitais e ajudam nossos clientes
                    a mergulharem fundo para encontrar oportunidades além da
                    superfície.
                  </p>
                  <h2 className="services__text-title">ANALYTICS</h2>{" "}
                  <p className="services__text-subtitle">
                    Organizam, visualizam e analisam dados de negócios para
                    criar soluções preditivas que ajudam na tomada de decisões
                    rápidas e assertivas.
                  </p>
                  <h2 className="services__text-title">CLOUD</h2>{" "}
                  <p className="services__text-subtitle">
                    Desenvolvem serviços de DevOps dentro de soluções que
                    estejam ou precisem ser reposicionados na “nuvem”.
                  </p>
                  <Button onClick={handleShow2} className="btn button-primary">
                    Fale com a gente.
                  </Button>
                  <Modal show={show2} onHide={handleClose2} size="lg">
                    <Modal.Header closeButton>
                      <Modal.Title>_Contato</Modal.Title>
                    </Modal.Header>
                    <p className="modal-text">
                      Obrigado pelo seu interesse na Projectiva. Seja qual for
                      sua questão, ajudaremos a direcioná-la para o local
                      correto.
                    </p>
                    <Modal.Body>
                      <div className="container">
                        <div className="row">
                          <Form.Group
                            className="col-lg-6 col-12"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Nome</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Leonardo Tavares"
                            />
                          </Form.Group>

                          <Form.Group
                            className="col-lg-6 col-12"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Empresa</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Projectiva Tecnologia Ltda"
                            />
                          </Form.Group>
                        </div>
                        <div className="row">
                          <Form.Group
                            className="col-lg-6 col-12"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>E-mail</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="leonardo.tavares@projectivadt.com.br"
                            />
                          </Form.Group>

                          <Form.Group
                            className="col-lg-6 col-12"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Telefone</Form.Label>
                            <Form.Control
                              type="phone"
                              placeholder="(11) 99999-9999"
                            />
                          </Form.Group>
                          <Form.Group
                            className=" col-12"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label>Como podemos ajudar?</Form.Label>
                            <Form.Control as="textarea" rows={3} />
                          </Form.Group>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        className="btn button-primary"
                        onClick={handleClose2}
                      >
                        Enviar
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="services__slider">
                <div className="services__image consulting">
                </div>
                <div className="services__text">
                  <h2 className="services__text-title">ERP IMPLEMENTATION</h2>{" "}
                  <p className="services__text-subtitle">
                    Criamos soluções ideais que integram vários sistemas e
                    plataformas de software. Nossos serviços de ERP são
                    projetados para ajudar a otimizar o uso do SAP e TOTV’S.
                  </p>
                  <h2 className="services__text-title">AGILE COACH</h2>{" "}
                  <p className="services__text-subtitle">
                    Especialista que atuará junto aos executivos da empresa
                    para a montagem das estratégias ágeis e de tecnologia.
                  </p>
                  <h2 className="services__text-title">GMO e PMO</h2>{" "}
                  <p className="services__text-subtitle">
                    Especialistas que trabalham em conjunto com a alta gestão
                    para a mudança organizacional de projetos complexos.
                  </p>
                  <h2 className="services__text-title">UX DESIGNER</h2>{" "}
                  <p className="services__text-subtitle">
                    Especialista que trabalha a jornada do usuário, sob
                    metodologias "User Centered Design".
                  </p>
                  <h2 className="services__text-title">CTO</h2>{" "}
                  <p className="services__text-subtitle">
                    Especialista que atuará junto à diretoria executiva da
                    empresa para a estruturação e garantia de execução da
                    estratégia corporativa.
                  </p>
                  <Button onClick={handleShow3} className="btn button-primary">
                    Fale com a gente.
                  </Button>
                  <Modal show={show3} onHide={handleClose3} size="lg">
                    <Modal.Header closeButton>
                      <Modal.Title>_Contato</Modal.Title>
                    </Modal.Header>
                    <p className="modal-text">
                      Obrigado pelo seu interesse na Projectiva. Seja qual for
                      sua questão, ajudaremos a direcioná-la para o local
                      correto.
                    </p>
                    <Modal.Body>
                      <div className="container">
                        <div className="row">
                          <Form.Group
                            className="col-lg-6 col-12"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Nome</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Leonardo Tavares"
                            />
                          </Form.Group>

                          <Form.Group
                            className="col-lg-6 col-12"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Empresa</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Projectiva Tecnologia Ltda"
                            />
                          </Form.Group>
                        </div>
                        <div className="row">
                          <Form.Group
                            className="col-lg-6 col-12"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>E-mail</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="leonardo.tavares@projectivadt.com.br"
                            />
                          </Form.Group>

                          <Form.Group
                            className="col-lg-6 col-12"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Telefone</Form.Label>
                            <Form.Control
                              type="phone"
                              placeholder="(11) 99999-9999"
                            />
                          </Form.Group>
                          <Form.Group
                            className=" col-12"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label>Como podemos ajudar?</Form.Label>
                            <Form.Control as="textarea" rows={3} />
                          </Form.Group>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        className="btn button-primary"
                        onClick={handleClose3}
                      >
                        Enviar
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="services__slider">
                <div className="services__image people">
                </div>
                <div className="services__text">
                  <h2 className="services__text-title">RECRUIT</h2>{" "}
                  <p className="services__text-subtitle">
                    Processo de R&S completo acompanhado por especialista em
                    Tech para garantir profissionais especializados e
                    altamente capacitados a desenvolver o esperado dentro da
                    sua companhia.{" "}
                  </p>
                  <h2 className="services__text-title">OUTSOURCING</h2>{" "}
                  <p className="services__text-subtitle">
                    Estrutura de TI adequada às necessidades específicas da
                    sua estratégia de negócios. Por meio das melhores práticas
                    do mercado e de profissionais especializados, realizamos
                    trabalhos dos mais diversos graus de complexidade,
                    presencial ou remotamente.{" "}
                  </p>
                  <Button onClick={handleShow4} className="btn button-primary">
                    Fale com a gente.
                  </Button>
                  <Modal show={show4} onHide={handleClose4} size="lg">
                    <Modal.Header closeButton>
                      <Modal.Title>_Contato</Modal.Title>
                    </Modal.Header>
                    <p className="modal-text">
                      Obrigado pelo seu interesse na Projectiva. Seja qual for
                      sua questão, ajudaremos a direcioná-la para o local
                      correto.
                    </p>
                    <Modal.Body>
                      <div className="container">
                        <div className="row">
                          <Form.Group
                            className="col-lg-6 col-12"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Nome</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Leonardo Tavares"
                            />
                          </Form.Group>

                          <Form.Group
                            className="col-lg-6 col-12"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Empresa</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Projectiva Tecnologia Ltda"
                            />
                          </Form.Group>
                        </div>
                        <div className="row">
                          <Form.Group
                            className="col-lg-6 col-12"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>E-mail</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="leonardo.tavares@projectivadt.com.br"
                            />
                          </Form.Group>

                          <Form.Group
                            className="col-lg-6 col-12"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Telefone</Form.Label>
                            <Form.Control
                              type="phone"
                              placeholder="(11) 99999-9999"
                            />
                          </Form.Group>
                          <Form.Group
                            className=" col-12"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label>Como podemos ajudar?</Form.Label>
                            <Form.Control as="textarea" rows={3} />
                          </Form.Group>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        className="btn button-primary"
                        onClick={handleClose4}
                      >
                        Enviar
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </section>
  );
}
