import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Pagination, EffectFade } from "swiper";

import video from "../../assets/videos/video1.mp4";
import video2 from "../../assets/videos/video2.mp4";
import video3 from "../../assets/videos/video3.mp4";
import video4 from "../../assets/videos/video4.mp4";


import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

export default function Video() {
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  const notify = (theme, type) =>
    toast.error(
      "O e-mail digitado não é válido. Favor verificar e tentar novamente."
    );

  const notify2 = (theme, type) =>
    toast.success("Sua mensagem foi enviada com sucesso!");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show3, setShow3] = useState(false);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);

  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  return (
    <Swiper
      loop={true}
      effect={"fade"}
      spaceBetween={0}
      pagination={{
        clickable: true,
      }}
      modules={[Pagination, EffectFade]}
      className="mySwiper"
    >
      <SwiperSlide>
        <div className="filter"></div>

        <video
          style={{ maxWidth: "100%", width: "100%" }}
          playsInline
          loop
          muted
          autoPlay={"autoplay"}
          alt="All the devices"
          src={video}
          className="videoHome"
          ref={videoEl}
        />
        <div className="container">
          <div className="box">
            <div className="box__container">
              <div className="box__header">
                <div className="box__title">Tecnologia no campo</div>
              </div>
              <div className="box__info">
                <p className="box__info-text">
                  Um setor que dispara, só o faz sob constante evolução. É por
                  isso que aqui o campo é atual, moderno e interpreta dados.
                  Nossa especialidade no Agro é transformar a jornada digital
                  das revendas, cooperativas e associados criando soluções ágeis
                  e preditivas que ajudam na tomada de decisões rápidas e
                  assertivas.
                </p>
                <Button onClick={handleShow} className="btn button-primary">
                  Fale com a gente.
                </Button>

                <Modal show={show} onHide={handleClose} size="lg">
                  <Modal.Header closeButton>
                    <Modal.Title>_Contato</Modal.Title>
                  </Modal.Header>

                  <p className="modal-text">
                    Obrigado pelo seu interesse na Projectiva. Seja qual for sua
                    questão, ajudaremos a direcioná-la para o local correto.
                  </p>
                  <Modal.Body>
                    <div className="container">
                      <div className="row">
                        <Form.Group
                          className="col-lg-6 col-12"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Nome</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Leonardo Tavares"
                          />
                        </Form.Group>

                        <Form.Group
                          className="col-lg-6 col-12"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Empresa</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Projectiva Tecnologia Ltda"
                          />
                        </Form.Group>
                      </div>
                      <div className="row">
                        <Form.Group
                          className="col-lg-6 col-12"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>E-mail</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="leonardo.tavares@projectivadt.com.br"
                            className="error"
                          />
                        </Form.Group>

                        <Form.Group
                          className="col-lg-6 col-12"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Telefone</Form.Label>
                          <Form.Control
                            type="phone"
                            placeholder="(11) 99999-9999"
                          />
                        </Form.Group>
                        <Form.Group
                          className=" col-12"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label>Como podemos ajudar?</Form.Label>
                          <Form.Control as="textarea" rows={3} />
                        </Form.Group>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button className="btn button-primary" onClick={notify}>
                      Enviar
                    </Button>
                    <ToastContainer
                      className="alert"
                      position="top-right"
                      autoClose={5000}
                      hideProgressBar
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                    />
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="filter"></div>
         <video
          style={{ maxWidth: "100%", width: "100%" }}
          playsInline
          loop
          autoPlay={"autoplay"}
          alt="All the devices"
          src={video2}
          ref={videoEl}
          className="videoHome"
        /> 

   
        <div className="container">
          <div className="box">
            <div className="box__container">
              <div className="box__header">
                <div className="box__title">Indústria 4.0</div>
              </div>
              <div className="box__info">
                <p className="box__info-text">
                  Mãos livres, sistemas de inteligência produtiva e evolução
                  disruptiva foram os principais ganhos tecnológicos da também
                  conhecida ‘quarta revolução industrial’. Incorporando a
                  operacionalização em tempo real, virtualizando processos e
                  descentralizando comandos, levamos este conceito simples de
                  digitalização para dentro das fábricas.
                </p>
                <Button onClick={handleShow2} className="btn button-primary">
                  Fale com a gente.
                </Button>

                <Modal show={show2} onHide={handleClose2} size="lg">
                  {" "}
                  <Modal.Header closeButton>
                    <Modal.Title>_Contato</Modal.Title>
                  </Modal.Header>
                  <p className="modal-text">
                    Obrigado pelo seu interesse na Projectiva. Seja qual for sua
                    questão, ajudaremos a direcioná-la para o local correto.
                  </p>
                  <Modal.Body>
                    <div className="container">
                      <div className="row">
                        <Form.Group
                          className="col-lg-6 col-12"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Nome</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Leonardo Tavares"
                          />
                        </Form.Group>

                        <Form.Group
                          className="col-lg-6 col-12"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Empresa</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Projectiva Tecnologia Ltda"
                          />
                        </Form.Group>
                      </div>
                      <div className="row">
                        <Form.Group
                          className="col-lg-6 col-12"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>E-mail</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="leonardo.tavares@projectivadt.com.br"
                          />
                        </Form.Group>

                        <Form.Group
                          className="col-lg-6 col-12"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Telefone</Form.Label>
                          <Form.Control
                            type="phone"
                            placeholder="(11) 99999-9999"
                          />
                        </Form.Group>
                        <Form.Group
                          className=" col-12"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label>Como podemos ajudar?</Form.Label>
                          <Form.Control as="textarea" rows={3} />
                        </Form.Group>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button className="btn button-primary" onClick={notify2}>
                      Enviar
                    </Button>
                    <ToastContainer
                      className="alert"
                      position="top-right"
                      autoClose={5000}
                      hideProgressBar
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                    />
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="filter"></div>
        <video
          style={{ maxWidth: "100%", width: "100%" }}
          playsInline
          loop
          className="videoHome"
          autoPlay={"autoplay"}
          alt="All the devices"
          src={video3}
          ref={videoEl}
        />
        <div className="container">
          <div className="box">
            <div className="box__container">
              <div className="box__header">
                <div className="box__title">Digital</div>
              </div>
              <div className="box__info">
                <p className="box__info-text">
                  A tecnologia tem confirmado seu valor nos negócios
                  mostrando-se fundamental na transformação de processos,
                  sistemas e comportamentos. Mas, para que ela seja ágil,
                  escalável e acessível é preciso simplificar. E para nós,
                  simplificar a jornada digital de nossos clientes é um objetivo
                  claro e tangível. Alinhar operações, negócios e processos que
                  verdadeiramente funcionam é o que chamamos aqui de
                  transformação digital.
                </p>
                <Button onClick={handleShow3} className="btn button-primary">
                  Fale com a gente.
                </Button>

                <Modal show={show3} onHide={handleClose3} size="lg">
                  <Modal.Header closeButton>
                    <Modal.Title>_Contato</Modal.Title>
                  </Modal.Header>
                  <p className="modal-text">
                    Obrigado pelo seu interesse na Projectiva. Seja qual for sua
                    questão, ajudaremos a direcioná-la para o local correto.
                  </p>
                  <Modal.Body>
                    <div className="container">
                      <div className="row">
                        <Form.Group
                          className="col-lg-6 col-12"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Nome</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Leonardo Tavares"
                          />
                        </Form.Group>

                        <Form.Group
                          className="col-lg-6 col-12"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Empresa</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Projectiva Tecnologia Ltda"
                          />
                        </Form.Group>
                      </div>
                      <div className="row">
                        <Form.Group
                          className="col-lg-6 col-12"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>E-mail</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="leonardo.tavares@projectivadt.com.br"
                          />
                        </Form.Group>

                        <Form.Group
                          className="col-lg-6 col-12"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Telefone</Form.Label>
                          <Form.Control
                            type="phone"
                            placeholder="(11) 99999-9999"
                          />
                        </Form.Group>
                        <Form.Group
                          className=" col-12"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label>Como podemos ajudar?</Form.Label>
                          <Form.Control as="textarea" rows={3} />
                        </Form.Group>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      className="btn button-primary"
                      onClick={handleClose}
                    >
                      Enviar
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="filter"></div>
        <video
          style={{ maxWidth: "100%", width: "100%" }}
          playsInline
          loop
          className="videoHome"
          autoPlay={"autoplay"}
          alt="All the devices"
          src={video4}
          ref={videoEl}
        />
        <div className="container">
          <div className="box">
            <div className="box__container">
              <div className="box__header">
                <div className="box__title">ERP</div>
              </div>
              <div className="box__info">
                <p className="box__info-text">
                  Estar pronto para solucionar problemas é o que nos faz
                  acreditar que aqui só ganhamos quando todos ganham. E é por
                  isso que nos especializamos em integrar, implementar e
                  orientar soluções que interliguem dados e processos em um
                  único local. Nosso modelo de consultoria em ERP para SAP e
                  TOTVs comprovam que a gestão da mudança pode ser ágil e
                  sobretudo, funcional.
                </p>
                <Button onClick={handleShow4} className="btn button-primary">
                  Fale com a gente.
                </Button>

                <Modal show={show4} onHide={handleClose4} size="lg">
                  <Modal.Header closeButton>
                    <Modal.Title>_Contato</Modal.Title>
                  </Modal.Header>
                  <p className="modal-text">
                    Obrigado pelo seu interesse na Projectiva. Seja qual for sua
                    questão, ajudaremos a direcioná-la para o local correto.
                  </p>
                  <Modal.Body>
                    <div className="container">
                      <div className="row">
                        <Form.Group
                          className="col-lg-6 col-12"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Nome</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Leonardo Tavares"
                          />
                        </Form.Group>

                        <Form.Group
                          className="col-lg-6 col-12"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Empresa</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Projectiva Tecnologia Ltda"
                          />
                        </Form.Group>
                      </div>
                      <div className="row">
                        <Form.Group
                          className="col-lg-6 col-12"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>E-mail</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="leonardo.tavares@projectivadt.com.br"
                          />
                        </Form.Group>

                        <Form.Group
                          className="col-lg-6 col-12"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Telefone</Form.Label>
                          <Form.Control
                            type="phone"
                            placeholder="(11) 99999-9999"
                          />
                        </Form.Group>
                        <Form.Group
                          className=" col-12"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label>Como podemos ajudar?</Form.Label>
                          <Form.Control as="textarea" rows={3} />
                        </Form.Group>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      className="btn button-primary"
                      onClick={handleClose}
                    >
                      Enviar
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
}
