/* eslint-disable react/style-prop-object */
export default function Cookies() {
  return (
    <div className="Cookie">
      <div className="Cookie__header">
        <div className="close">x</div>
      </div>
      <div className="Cookie__body">
        <p className="Cookie__body-text">
          Nós utilizamos cookies e outras tecnologias semelhantes para analisar
          sua experiência no site e personalizar conteúdos e anúncios durante
          sua navegação. Ao navegar pelo site, você autoriza a Projectiva DT a
          realizar tal monitoramento. Conheça nossa{" "}
          <span>Política de Privacidade</span>.
        </p>
      </div>
      <div className="Cookie__footer">
        <span className="button">OK</span>
      </div>
    </div>
  );
}
