import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import React, { useEffect, useRef } from "react";
import { browserName } from "react-device-detect";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import rocket from "../../assets/rocket/rocket-chrome.webm";
import rocketmp4 from "../../assets/rocket/rocket-safari.mp4";

export default function Testimonial() {
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  return (
    <section className="testimonial">
      <div className="container">
        <div className="testimonial__container row">
          <div className="col-12 col-md-6  d-block d-lg-flex">
            {browserName !== "Safari" && (
              <video
                style={{ maxWidth: "100%", width: "100%" }}
                playsInline
                loop
                muted
                autoPlay={"autoplay"}
                alt="All the devices"
                src={rocket}
                className="videoRocket"
                ref={videoEl}
              />
            )}
            {browserName === "Safari" && (
              <video
                style={{ maxWidth: "100%", width: "100%" }}
                playsInline
                loop
                muted
                autoPlay={"autoplay"}
                alt="All the devices"
                src={rocketmp4}
                className="videoRocketMP4"
                ref={videoEl}
              />
            )}
          </div>
          <div className="col-12 col-md-6">
            <div className="testimonial__slider">
              <Swiper
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                autoHeight={true}
                className="mySwiper"
              >
                <SwiperSlide>
                  <div className="textBox">
                    <h1 className="textBox__title">
                      Aqui só ganhamos quando todos ganham.
                    </h1>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="textBox">
                    <h1 className="textBox__title">
                      Acreditamos que a sinergia acontece a partir da energia
                      conjunta.{" "}
                    </h1>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="textBox">
                    <h1 className="textBox__title">
                      Consideramos que falhar é parte da inovação e
                      transformação.
                    </h1>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="textBox">
                    <h1 className="textBox__title">
                      Os resultados só vêm através da colaboração.
                    </h1>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
