import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

import ApostropheDir from "../../assets/icons/apostrophe-dir.png";
import ApostropheEsq from "../../assets/icons/apostrophe-esq.png";


import ClientUsaflex from "../../assets/background/client-usaflex.png";

import ClientUnes from "../../assets/background/client-unes.png";

import ClientSb from "../../assets/background/client-sb.png";

import ClientAdvb from "../../assets/background/client-advb.png";
import "swiper/css";
import "swiper/css/pagination";

export default function SectionDepoiments() {
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + "</span>";
    },
  };

  return (
    <section className="depositions">
      <div className="depositions__container">
        <div className="container">
          <div className="depositions__header">
            <h1 className="depositions__header-title">Depoimentos</h1>
          </div>

          <div className="slider">
            <div className="slider__header">
              <img src={ApostropheEsq} alt="dir" />
            </div>
            <Swiper
              loop={true}
              spaceBetween={0}
              pagination={{
                clickable: true,
                pagination,
              }}
              autoHeight={true}
              centeredSlides={true}
              modules={[Pagination]}
              className="mySwiper"
            >
              <SwiperSlide>
                <p className="slider__text">
                  A Projectiva mostrou-se um parceiro flexível e ágil
                  conseguindo identificar e disponibilizar rapidamente
                  profissionais com bom nível técnico e comprometidos para nos
                  apoiar em importantes frentes do nosso projeto (Gestão de
                  Migração de Dados e consultores para as frentes Fiscal, PP e
                  FI).
                </p>
                <div className="depositions__container">
                  <div className="depositions__image">
                    <img src={ClientUsaflex} alt="depositions" />
                  </div>
                  <div className="depositions__text">
                    <h1 className="name">Luis Gustavo Furlan</h1>
                    <p className="office">Gerente de TI</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <p className="slider__text">
                  Super recomendamos o serviço da Projectiva por meio do
                  Consultor em Transformação Ágil Prof. Cristiano Galina.
                  Percebemos nitidamente os efeitos deste trabalho, dentre eles,
                  a mudança de mindset dos participantes para compreenderem
                  melhor a importância do conceito de negócio com impactos na
                  performance, automotivação dos colaboradores envolvidos,
                  organização e desenvolvimento das rotinas diárias de forma
                  ágil e mais assertiva, engajamento do time para o que
                  realmente é essencial para a instituição, dentre outras
                  conquista que tivemos nos últimos 5 meses de trabalho. O
                  profissional Galina é super dedicado, engajado no projeto em
                  que se compromete e tem habilidade de mediar conflitos em prol
                  do que se pretende no projeto que está conduzindo. Por essas
                  razões, estamos diante de um profissional altamente capacitado
                  para apoiar as organizações neste novo tempo, cenário esse
                  altamente veloz, com necessidade de profissionais que resolvam
                  os problemas e tragam novas performances para as organizações
                  contemporâneas. Prestação de serviço super aprovada!
                </p>
                <div className="depositions__container">
                  <div className="depositions__image">
                    <img src={ClientUnes} alt="depositions" />
                  </div>
                  <div className="depositions__text">
                    <h1 className="name">Gisele Silveira Coelho Lopes</h1>
                    <p className="office">
                      Pró-Reitora de Planejamento e Desenvolvimento
                      Institucional (UNESC)
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <p className="slider__text">
                  A consultoria da Projectiva chegou no caminho da SB+ Eventos
                  num momento de grande virada. Após um ano de pandemia e doze
                  anos de empresa, nos vimos diante da principal mudança no
                  nosso modelo de negócio e para isso buscamos uma assessoria
                  para nos dar suporte. Em apenas um encontro, de uma forma
                  dinâmica, objetiva e muito clara, o Galina nos apresentou
                  muitas possibilidades e ali decidimos fechar a parceria.
                  Estamos felizes com o desenvolvimento do trabalho e já
                  colhemos frutos das medidas tomadas.
                </p>
                <div className="depositions__container">
                  <div className="depositions__image">
                    <img src={ClientSb} alt="depositions" />
                  </div>
                  <div className="depositions__text">
                    <h1 className="name">Carla Barcelos</h1>
                    <p className="office">Cofundadora e Diretora (SB Eventos)</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <p className="slider__text">
                Tivemos uma experiência bastante positiva com a Projectiva no Planejamento Estratégico da Advb/SC em 2021, proporcionando um importante suporte para uma transformação ágil dentro da entidade.

O trabalho continua com o assessoramento e a manutenção dos objetivos propostos.

Nos sentimos hoje mais capacitados enquanto grupo, nas nossas entregas e recomendamos o trabalho do consultor Cristiano Galina e da equipe da Projectiva.
                </p>
                <div className="depositions__container">
                  <div className="depositions__image">
                    <img src={ClientAdvb} alt="depositions" />
                  </div>
                  <div className="depositions__text">
                    <h1 className="name">Angela Gonçalves</h1>
                    <p className="office">Presidente (ADVB SC)</p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
            <div className="slider__footer">
              <img src={ApostropheDir} alt="esq" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
