import TimelineHome from "../../components/TimelineHome";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import SectionClient from "../../components/SectionClient";
import SectionDepoiments from "../../components/SectionDepoiments";
import SectionServices from "../../components/SectionServices";
import SectionSpeacials from "../../components/SectionSpeacials";
import Testimonial from "../../components/Testimonial";
import Video from "../../components/Video";
import Cookies from "../../components/Cookies";
export default function Home() {
  return (
    <>
      <Header />

      <Video />

      <Testimonial />

      <TimelineHome />

      <SectionServices />

      <SectionSpeacials />

      <SectionDepoiments />
  
      <SectionClient />

      <Cookies/>

      <Footer />
    </>
  );
}
