import ProjectivaAgro from "../../assets/background/agro.png";
import ProjectivaIndustria from "../../assets/background/industria.png";
import ProjectivaIot from "../../assets/background/iot.png";

export default function SectionSpeacials() {
  return (
    <section className="specialties">
      <div className="specialties__header">
        <h1 className="specialties__header-title">
          Especialidades tecnológicas
        </h1>
      </div>
      <div className="specialties__content">
        <img src={ProjectivaAgro} alt="Agro" />
        <img src={ProjectivaIndustria} alt="Industria" />
        <img src={ProjectivaIot} alt="Iot" />
      </div>
    </section>
  );
}
