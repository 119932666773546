/* eslint-disable no-undef */
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";

import { Navigation, Pagination, Scrollbar } from "swiper";

export default function TimelineHome() {
  const items = [
    {
      time: "Jun/2016",
      text: "Nascemos como uma consultoria em gestão de projetos dentro das áreas de tecnologia da informação e arquitetura. ",
    },
    {
      time: "Jan/2017",
      text: "Buscamos parcerias estratégicas para alavancar nossas especialidades de negócios e começamos a compreender como era benéfica a transformação digital que ofereciamos.",
    },
    {
      time: "Jan/2018",
      text: "Já nos destacávamos como prestadora de serviços para grandes empresas como META, Stefanini e UCS dentro das áreas de tecnologia e projetos arquitetônicos ",
    },
    {
      time: "Dez/2018",
      text: "Redesenhamos nossa estratégia de desenvolvimento de softwares para soluções e transformações em um mundo ainda mais digital e instantâneo;",
    },
    {
      time: "Mar/2019",
      text: "Ganhamos a primeira e atual tagline, simbolizada pelas letras ‘DT’, representadas por Design, Digital, Disruptivo e Transformação, Tecnologia, Tendências",
    },
    {
      time: "Jan/2020",
      text: "Dobramos nosso número de clientes e triplicamos nosso quadro de colaboradores, buscando sempre e em primeiro lugar, atender com excelência.",
    },
    {
      time: "Ago/2020",
      text: "Cristiano Galina assume o papel de CEO em nossa casa, organizando a nova estrutura para projetos junto ao atual sócio Leonardo Tavares.",
    },
    {
      time: "Jan/2021",
      text: "Surpreendemos o mercado com soluções sob medida (MVP) em enfrentamento à pandemia, atendendo aos nossos clientes no modelo as a service.",
    },
    {
      time: "Ago/2022 ",
      text: "Sob novo olhar, buscamos redesenhar nossa estratégia e apostar em nichos já explorados, agregando ao nosso portfólio os segmentos de IOT, Agro e Indústria 4.0 ",
    },
  ];

  const CarouselCard = ({ time, text }) => {
    return (
      <div className="timeline__card">
        <div className="timeline__card__time">{time}</div>
        <div className="timeline__card__text">{text}</div>
      </div>
    );
  };

  return (
    <section className="timeline">
      <div className="container">
        <div className="timeline__header">
          <h1 className="timeline__title" >
            Nós respiramos a evolução todos os dias
          </h1>
        </div>
        <div className="timeline__content">
          <Swiper
            navigation
            slidesPerView={1}
            modules={[Navigation, Pagination, Scrollbar]}
          >
            {items.map((item, index) => (
              <SwiperSlide>
                <CarouselCard time={item.time} text={item.text} key={index} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
}
