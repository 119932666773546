/* eslint-disable no-unused-vars */
import { useLayoutEffect, useState } from "react";
import { ReactComponent as LogoDesk } from "../../assets/logo/logo.svg";
import { ReactComponent as Hamb } from "../../assets/icons/icon-hamb.svg";
import { ReactComponent as Instagram } from "../../assets/icons/icon-insta.svg";
import { ReactComponent as Facebook } from "../../assets/icons/icon-face.svg";
import { ReactComponent as Linkedin } from "../../assets/icons/icon-linkedin.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/js/dist/collapse";

export default function Header() {
  const [open, setOpen] = useState(false); 
  const [openSearch, setOpenSearch] = useState("");
  const [headerFixed, setHeaderFixed] = useState(false);

  const [toggle, setToggle] = useState(false);

  useLayoutEffect(() => {
    if (window.innerWidth < 992) {
      setHeaderFixed(true);
    } else {
      document.addEventListener("scroll", () => {
        if (window.scrollY >= 100) {
          setHeaderFixed(true);
        } else {
          setHeaderFixed(false);
        }
      });
    }
  }, [headerFixed]);

  return (
    <>
      <header className={`header ${headerFixed ? "" : "bg-transparent"}`}>
        <div className="container">
          <div className="header__content">
            <div className="header__content-menu">
              <div
                className="hamb collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#accordionHeader"
                aria-expanded="false"
                aria-controls="accordionHeader"
              >
                <Hamb />
              </div>
              <div className="menu collapse accordion" id="accordionHeader">
                <ul className="menu__list">
                  <li className="menu__list-item">Home</li>
                  <li className="menu__list-item">Institucional</li>
                  <li className="menu__list-item">Serviços</li>
                  <li className="menu__list-item">Clientes e Parceiros</li>
                  <li className="menu__list-item">Contato</li>
                  <li className="menu__list-item">
                    <Linkedin />
                  </li>
                  <li className="menu__list-item">
                    <Facebook />
                  </li>
                  <li className="menu__list-item">
                    <Instagram />
                  </li>
                </ul>
              </div>
            </div>
            <div className="header__logo">
              <LogoDesk />
            </div>
            <div className="header__container">
              <div className="header__itens">
                <div className="link">
                  <p>Home</p>
                </div>
                <div className="link">
                  <p>Institucional</p>
                </div>
                <div className="link">
                  <p>Serviços</p>
                </div>
                <div className="link">
                  <p>Clientes e Parceiros</p>
                </div>
                <div className="link">
                  <p>Contato</p>
                </div>
              </div>
              <div className="header__midias">
                <div className="link">
                  <Linkedin />
                </div>
                <div className="link">
                  <Instagram />
                </div>
                <div className="link">
                  <Facebook />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
